
import { defineComponent, PropType, reactive } from 'vue';
import { InstallerJoinPopup, GeneralJoinPopup } from '@/components';

import { SocialAccountInfo } from 'hems/auth/account';
import { EnvLocale } from 'hems';
import { TermsService } from '@hems/service';
import { TERMS_TYPE } from '@hems/util/src/constant';
import { TermsHelper } from '@hems/util';
import { EffectiveTermsInfo } from 'hems/terms';

export default defineComponent({
  name: 'JoinPopupContainer',
  components: {
    InstallerJoinPopup,
    GeneralJoinPopup,
  },
  emits: ['close'],
  props: {
    joinType: {
      type: String as PropType<'general' | 'installer'>,
      default: 'general',
    },
    social: Object as PropType<SocialAccountInfo>,
    locale: String as PropType<EnvLocale>,
  },
  async setup(props, { emit }) {
    const termsService = new TermsService(window.axiosInstance.axios);
    const termsType = props.joinType === 'general' ? TERMS_TYPE.USER : TERMS_TYPE.INSTALLER;
    const state = reactive({
      termsList: [] as EffectiveTermsInfo[],
    });

    const getTermsList = async () => {
      try {
        const terms = await termsService.getTerms(termsType);
        state.termsList = TermsHelper.getSortedTermsByEffectiveDate(terms);
      } catch (e) {
        console.error(e);
      }
    };

    await getTermsList();

    return {
      state,
    };
  },
});
