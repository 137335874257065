import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GeneralJoinPopup = _resolveComponent("GeneralJoinPopup")!
  const _component_InstallerJoinPopup = _resolveComponent("InstallerJoinPopup")!

  return (_ctx.joinType === 'general')
    ? (_openBlock(), _createBlock(_component_GeneralJoinPopup, {
        key: 0,
        social: _ctx.social,
        locale: _ctx.locale,
        "terms-list": _ctx.state.termsList,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, null, 8, ["social", "locale", "terms-list"]))
    : (_ctx.joinType === 'installer')
      ? (_openBlock(), _createBlock(_component_InstallerJoinPopup, {
          key: 1,
          social: _ctx.social,
          locale: _ctx.locale,
          "terms-list": _ctx.state.termsList,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, null, 8, ["social", "locale", "terms-list"]))
      : _createCommentVNode("", true)
}